<template>
 <el-card shadow="never" style="height: 100%">
   <el-form
       :model="ruleForm"
       :rules="rules"
       status-icon
       ref="ruleForm"
       label-width="100px"
       class="demo-ruleForm"
   >
     <el-form-item label="类别ID" prop="id" v-if="false">
       <el-input v-model="ruleForm.id" autocomplete="off" :disabled="true" ></el-input>
     </el-form-item>
     <el-form-item label="类别名称" prop="name">
       <el-input v-model="ruleForm.name" autocomplete="off" @blur="checkTypeName()" style="width: 250px;"></el-input>
       <el-button type="primary" style="margin-left: 20px;" @click="editTypeBig('ruleForm')">提交</el-button>
     </el-form-item>
   </el-form>
 </el-card>
</template>
<script>
import $ from "jquery";

export default {
  data() {
    return {
      rules: {
        name: [
          {required: true, message: "请输入类别名称", trigger: "blur"},
        ],
      },
      ruleForm: {
        id: "",
        name: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkTypeName() {
      this.$api.get(
        "/type/checkTypeName",
        null,
        { id: this.ruleForm.id, parentId: null, name: this.ruleForm.name },
        (successRes) => {
          console.log("...............");
          console.log(successRes);
          if (!successRes.data) {
            this.$message("类别名称已存在!");
          }
        },
        (failureRes) => {
          this.$message("服务出错!");
        }
      );
    },
    getUserInfoById(id) {
      this.$api.get(
        "/type/big/getById",
        null,
        { id: id },
        (successRes) => {
          console.log(successRes);
          this.ruleForm = successRes.data;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    init() {
      
      const userId = this.$route.query.id;
      this.getUserInfoById(userId);
    },
    editTypeBig(ruleForm) {
      // console.log(this.$refs[ruleForm]);
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
           this.$api.post(
        "/type/big/update",
        null,
        {
          id: this.ruleForm.id,
          name: this.ruleForm.name,
        },
        (successRes) => {
          console.log(successRes);
          if (successRes.status == 200) {
            this.$message("操作成功");
            this.$router.push({ name: "masterList" });
          } else {
            this.$message(successRes.message);
          }
        },
        (failureRes) => {
          this.$message("操作失败");
        }
      );
          
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    },
  },
};
</script>